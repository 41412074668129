











































import { Component, Vue } from "vue-property-decorator";
import agent from "@/helpers/agent";
import ClaimDTO from "@/models/claim";
import Role from "@/models/role";
import Notifier from "@/helpers/notifier";

@Component
export default class UpdateView extends Vue {
  claims: ClaimDTO[] = [];
  role: Role = {
    id: "",
    name: "",
    claimDescription: "",
  };
  selectedClaims: ClaimDTO[] = [];

  pagination = {
    sortBy: "Action",
    descending: false,
    page: 0,
    rowsPerPage: 5,
    rowsNumber: 10,
  };
  loading = true;
  isInRole = false;
  filter = "";
  columns = [
    {
      name: "Module",
      required: true,
      label: "Module",
      align: "left",
      field: (row: ClaimDTO) => row.module,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Action",
      required: true,
      label: "Action",
      align: "left",
      field: (row: ClaimDTO) => row.action,
      sortable: true,
    },
    {
      name: "Type",
      required: true,
      label: "Type",
      align: "left",
      field: (row: ClaimDTO) => row.type,
      sortable: true,
    },
  ];
  mounted() {
    agent.Roles.details(this.$route.query.id as string).then((role) => {
      this.role = role;
      this.onRequest({
        pagination: this.pagination,
        filter: "",
      });
    });
  }
  claimIsInRole(claimValue: string) {
    return this.role.claimDescription.includes(claimValue);
  }
  selectRole(details: any) {
    if (details.rows && details.rows.length > 0) {
      const claim = details.rows[0];
      agent.Roles.editRole(
        this.role.name,
        details.added as boolean,
        claim
      ).then(() => Notifier.showPositive("Role successfully updated"));
      return;
    }
    Notifier.showError("There was an error updating the role claim");
  }

  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Roles.listClaims(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.claims = rows.rows.map((x) => {
          return {
            ...x,
            checked: this.claimIsInRole(x.value),
          };
        });
        this.selectedClaims = [
          ...this.selectedClaims,
          ...this.claims.filter((x) => x.checked),
        ];
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "FormFinancialinstitution",
      query: { id },
    });
  }
}
